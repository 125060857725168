export enum QUERY_KEY {
  GET_REVENUE_FILTER_TYPES = 'GET_REVENUE_FILTER_TYPES',
  GET_REVENUE = 'GET_REVENUE',
  GET_ALL_REVENUE = 'GET_ALL_REVENUE',
  GET_ALL_REPORT = 'GET_ALL_REPORT',
  GET_REVENUE_SUMMARY = 'GET_REVENUE_SUMMARY',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
}

export enum STEP {
  INPUT_PHONE_NUMBER = 'INPUT_PHONE_NUMBER',
  INPUT_OTP = 'INPUT_OTP',
  INPUT_PAYMENT_INFO = 'INPUT_PAYMENT_INFO',
}

export enum PAYMENT_METHOD {
  CREDIT_CARD = 'CREDIT_CARD',
  APPLE_PAY = 'APPLE_PAY',
}
