import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Box, Button, Stack, Typography } from '@mui/material';

import { MainProvider } from '~providers';
import { handleOpenApp } from '~utils/index';

export const MainLayout: React.FC = () => {
  const location = useLocation();
  const disableHeader = location.pathname === '/success';

  return (
    <MainProvider>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100vw',
          minHeight: '100vh',
          maxWidth: '1024px',
          margin: 'auto',
        }}
      >
        {!disableHeader && (
          <Stack
            right={0}
            left={0}
            position={'fixed'}
            paddingY={3}
            paddingX={2}
            minHeight={'90px'}
            justifyContent={'space-between'}
            direction="row"
            alignItems="center"
            bgcolor={'#F5FBFE'}
            borderBottom={'1px solid #99DBFF'}
            zIndex={100}
          >
            <img src="/images/logo-black.svg" alt="logo" width={'130'} />
            <Button
              variant="outlined"
              color="primary"
              sx={{ borderRadius: 5 }}
              onClick={handleOpenApp}
            >
              <Typography variant="body2" fontWeight={500}>
                Use App
              </Typography>
            </Button>
          </Stack>
        )}
        <Stack
          overflow={'auto'}
          sx={{
            justifyContent: 'space-between',
            flex: 1,
            marginTop: disableHeader ? 0 : '90px',
          }}
        >
          <Stack flex={1}>
            <Outlet />
          </Stack>
          <Stack
            bgcolor={'white'}
            color="secondary"
            justifyContent={'center'}
            alignItems="center"
            direction="row"
            p={3.5}
          >
            <img src="/images/chat-bubble.svg" alt="chat-icon" width={'24'} />
            <Box
              component={'a'}
              href="sms:+1 (213) 451-6667"
              sx={{ textDecoration: 'unset' }}
            >
              <Typography
                variant="body2"
                fontWeight={500}
                color={'primary'}
                marginLeft={1}
              >
                Questions? Text customer support
              </Typography>
            </Box>
          </Stack>
        </Stack>
      </Box>
    </MainProvider>
  );
};
