import React from 'react';
import { Button, ButtonProps, CircularProgress } from '@mui/material';

type Props = ButtonProps & {
  children: React.ReactNode;
  isLoading?: boolean;
};

export const MainButton: React.FC<Props> = ({
  children,
  isLoading = false,
  ...props
}) => {
  let disableStyle = {};

  switch (props.variant) {
    case 'text':
      disableStyle = {
        ':disabled': {
          color: '#99DBFF',
        },
      };
      break;
    case 'contained':
      disableStyle = {
        ':disabled': {
          backgroundColor: '#EDF8FE',
          color: '#99DBFF',
        },
      };
  }

  return (
    <Button
      {...props}
      sx={{
        paddingY: 2,
        borderRadius: 50,
        ...disableStyle,
        ...props.sx,
      }}
    >
      {isLoading ? <CircularProgress size={20} color="inherit" /> : children}
    </Button>
  );
};
