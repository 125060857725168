import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Radio,
  Stack,
  Typography,
} from '@mui/material';
import {
  PaymentRequestButtonElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { PaymentRequest, PaymentRequestTokenEvent } from '@stripe/stripe-js';
import Parse from 'parse';

import { CreditCardPaymentForm } from './CreditCardPaymentForm';

import { PAYMENT_METHOD } from '~common';
import { MainButton } from '~components/atoms';
import { useMainContext } from '~providers';

export const PaymentForm = () => {
  const {
    paymentMethod,
    onChangePaymentMethod,
    isEnabledEjectBtn,
    customerId,
    ejectBattery,
    chargingBoxPricing,
  } = useMainContext();
  const [error, setError] = useState<string | null>(null);
  const [processing, setProcessing] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(
    null,
  );

  const convertPreAuthAmountToCent = (amount?: string) => {
    if (isNaN(Number(amount))) return 0;
    return Number(amount) * 100;
  };

  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: 'PreAuth amount',
          amount: convertPreAuthAmountToCent(chargingBoxPricing?.preAuthAmount),
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then((result) => {
        if (result && result.applePay) {
          setPaymentRequest(pr);
        }
      });

      pr.on('token', async (event: PaymentRequestTokenEvent) => {
        try {
          await ejectBattery(event.token.id);
          event.complete('success');
        } catch (err) {
          toast.error((err as Error).message);
          event.complete('fail');
        }
      });
    }
  }, [chargingBoxPricing?.preAuthAmount, ejectBattery, stripe]);

  const savePaymentInfo = async (paymentMethodId: string) => {
    try {
      await Parse.Cloud.run('savePaymentInfo', {
        cardToken: paymentMethodId,
        customerId,
      });
    } catch (error) {
      throw new Error(
        (error as Error).message ??
          'An unknown error occurred when save payment info!',
      );
    }
  };

  const handleEjectBattery = async (
    event: React.SyntheticEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
    setProcessing(true);

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement('cardNumber');
    if (!cardElement) return;

    const { error, token } = await stripe.createToken(cardElement);

    if (error && error.code !== 'setup_intent_unexpected_state') {
      setError(
        error.message ??
          'An unknown error occurred when setup credit card intent!',
      );
      setProcessing(false);

      return;
    }

    let paymentMethodId = null;

    if (error && error.code === 'setup_intent_unexpected_state') {
      // this card already setup intent success
      paymentMethodId = error.setup_intent?.payment_method as string;
    } else {
      paymentMethodId = token?.id as string;
    }

    setError(null);

    try {
      // Submit the payment method to your server
      await savePaymentInfo(paymentMethodId as string);
      await ejectBattery();
    } catch (err) {
      toast.error((err as Error).message);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <>
      <Stack sx={{ border: '1px solid #DBDBDB', borderRadius: 1 }}>
        <Accordion
          disableGutters
          elevation={0}
          expanded={paymentMethod === PAYMENT_METHOD.CREDIT_CARD}
          onChange={onChangePaymentMethod(PAYMENT_METHOD.CREDIT_CARD)}
        >
          <AccordionSummary
            expandIcon={
              <IconButton size="small">
                <Radio checked={paymentMethod === PAYMENT_METHOD.CREDIT_CARD} />
              </IconButton>
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Stack direction="row" gap={2} alignItems={'center'}>
              <Stack width={'35px'} alignItems="center">
                <img
                  src="/images/credit_card.svg"
                  alt="Credit Card"
                  width={19}
                />
              </Stack>

              <Typography variant="body1" fontWeight={600}>
                Add credit or debit card
              </Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <CreditCardPaymentForm error={error} processing={processing} />
          </AccordionDetails>
        </Accordion>
        {paymentRequest && (
          <Accordion
            elevation={0}
            expanded={paymentMethod === PAYMENT_METHOD.APPLE_PAY}
            onChange={onChangePaymentMethod(PAYMENT_METHOD.APPLE_PAY)}
          >
            <AccordionSummary
              expandIcon={
                <IconButton size="small">
                  <Radio checked={paymentMethod === PAYMENT_METHOD.APPLE_PAY} />
                </IconButton>
              }
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Stack direction="row" gap={2} alignItems={'center'}>
                <img src="/images/ApplePay.svg" alt="Apple Pay" width={35} />
                <Typography variant="body1" fontWeight={600}>
                  Apple Pay
                </Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <PaymentRequestButtonElement options={{ paymentRequest }} />
            </AccordionDetails>
          </Accordion>
        )}
      </Stack>
      <Stack
        direction="row"
        sx={{ border: '1px solid #DBDBDB', borderRadius: 2 }}
        p={2}
      >
        <Stack mt={0.5} marginRight={2}>
          <img src="/images/info.svg" alt="info" width={16} />
        </Stack>

        <Typography variant="body2" color="#6A737D">
          Kindly return charger to any station when finished to avoid additional
          charge.
        </Typography>
      </Stack>
      <MainButton
        variant="contained"
        disabled={!isEnabledEjectBtn || processing}
        onClick={handleEjectBattery}
        isLoading={processing}
      >
        <Typography variant="body1" fontWeight={500}>
          Eject Charger
        </Typography>
      </MainButton>
    </>
  );
};
