import React from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from 'react-router-dom';

import { HomePage, RentalPage, SuccessPage } from '~components/pages';
import { WelcomeBackPage } from '~components/pages/WelcomeBackPage';
import { MainLayout } from '~components/templates';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<MainLayout />}>
        <Route index element={<HomePage />} />
        <Route path="/fuzebox" element={<RentalPage />} />
        <Route path="/success" element={<SuccessPage />} />
        <Route path="/welcome-back" element={<WelcomeBackPage />} />
      </Route>
    </>,
  ),
);
