import React, { useMemo } from 'react';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js';

import { PaymentForm } from './PaymentForm';

import { useMainContext } from '~providers';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY ?? '');

export const StepThree = () => {
  const { clientSecret, isLoadingClientStripeSecret, chargingBoxPricing } =
    useMainContext();

  const options: StripeElementsOptions = useMemo(
    () => ({
      clientSecret,
      appearance: {
        theme: 'stripe',
      },
    }),
    [clientSecret],
  );

  const onClickViewTerms = () => {
    window?.open(
      process.env.REACT_APP_VIEW_TERMS_URL ??
        'https://www.chargefuze.com/terms-of-service/',
      '_blank',
    );
  };

  return (
    <>
      <Stack display="grid" sx={{ gridRowGap: 16 }}>
        <Typography variant="caption" fontWeight={500} color={'#6A737D'}>
          STEP 3 OF 3
        </Typography>
        <Typography variant="h5" fontWeight={600}>
          Choose how to pay
        </Typography>
        <Typography variant="body1" fontWeight={400} color="#6A737D">
          {`Your card will be pre-authorized for $${
            chargingBoxPricing?.preAuthAmount ?? 10
          }. This is a temporary hold and
          will be reversed within 5 business days.`}
          <Box
            component="span"
            marginLeft={'0.3rem'}
            sx={{ color: 'primary.main', fontWeight: 400, fontSize: '1rem' }}
            onClick={onClickViewTerms}
          >
            View terms
          </Box>
        </Typography>
      </Stack>
      <Stack display="grid" sx={{ gridRowGap: 16 }}>
        <Typography variant="body1" fontWeight={600} color="#009EFA">
          Rental Details
        </Typography>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body1" fontWeight={400} color="#6A737D">
            1x Charger
          </Typography>
          <Typography variant="body1" fontWeight={400} color="#6A737D">
            Pricing on screen
          </Typography>
        </Stack>
      </Stack>
      {isLoadingClientStripeSecret ? (
        <Stack justifyContent={'center'} alignItems="center">
          <CircularProgress />
        </Stack>
      ) : (
        clientSecret && (
          <Elements stripe={stripePromise} options={options}>
            <PaymentForm />
          </Elements>
        )
      )}
    </>
  );
};
