import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Stack, Typography } from '@mui/material';

import { useNavigateInApp } from '~hooks/index';
import { handleOpenApp } from '~utils/index';

export const HomePage = () => {
  const navigate = useNavigateInApp();

  const navigateToRentalPage = () => {
    navigate('/fuzebox');
  };

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      flex={1}
      sx={{
        background: 'url(/images/background.svg)',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        px: 5.5,
        width: '100%',
        height: '100%',
        color: 'white',
        textAlign: 'center',
      }}
    >
      <Stack p={4} alignItems="center">
        <img src="/images/logo.svg" alt="logo" width={'142'} />
      </Stack>
      <Stack display="grid" sx={{ gridRowGap: 56 }}>
        <Typography variant="h4" fontWeight={600}>
          Charge any device, anywhere, anytime!
        </Typography>
        <Typography variant="body1" fontWeight={500}>
          {
            'Rent and carry a portable FUZE charger to power your devices on the go. Simply return it to any chargeFUZE station when you’re finished.'
          }
        </Typography>
        <Stack width="100%" display="grid" sx={{ gridRowGap: 24 }}>
          <Button
            variant="contained"
            fullWidth
            sx={{
              bgcolor: 'white !important',
              color: '#009EFA',
              py: '18px',
              borderRadius: '50px',
            }}
            onClick={navigateToRentalPage}
          >
            <Typography variant="body1" fontWeight={500}>
              Rent a FUZE charger
            </Typography>
          </Button>
          <Button
            variant="outlined"
            fullWidth
            sx={{
              border: '1px solid white',
              bgcolor: 'transparent !important',
              color: 'white',
              py: '18px',
              borderRadius: '50px',
              '&:hover': {
                border: '1px solid white',
                bgcolor: 'transparent',
                color: 'white',
              },
            }}
            startIcon={<AddIcon />}
            onClick={handleOpenApp}
          >
            <Typography variant="body1" fontWeight={500}>
              Download chargeFUZE app
            </Typography>
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};
