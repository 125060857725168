import Reaptcha from 'reaptcha';

type Props = {
  forwardRef: React.MutableRefObject<Reaptcha | null>;
  onVerify: (token: string) => void;
  onExpire: () => void;
};

export const Captcha: React.FC<Props> = ({
  forwardRef,
  onVerify,
  onExpire,
}) => {
  return (
    <Reaptcha
      sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITEKEY}
      onVerify={onVerify}
      onExpire={onExpire}
      ref={forwardRef}
    />
  );
};
