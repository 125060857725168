export * from './phone.util';

export function iOS() {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
}

export function isAndroid() {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf('android') > -1;
}

export const handleOpenApp = () => {
  window?.open(
    iOS()
      ? process.env.REACT_APP_IOS_DEEP_LINK
      : isAndroid()
      ? process.env.REACT_APP_ANDROID_DEEP_LINK
      : process.env.REACT_APP_CF_MAIN_PAGE_LINK,
  );
};

export const isDevEnv = () => {
  return process.env.REACT_APP_STAGE === 'dev';
};
