import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useLocalStorage } from 'react-use';
import Parse from 'parse';
import { createContext, useContext } from 'use-context-selector';

import { DEFAULT_COUNTRY_DATA, PAYMENT_METHOD, STEP } from '~common';
import { useNavigateInApp } from '~hooks/index';
import { checkOnlineStatus } from '~services/rental.service';
import { TChargingBoxPricing, TPaymentInfo } from '~types';
import { isValidPhoneNumber } from '~utils';

type MainContextType = {
  step: STEP | null;
  changeStep: (step: STEP) => void;
  getOTP: (captchaToken?: string) => Promise<void>;
  login: (code: string) => Promise<void>;
  isLoadingLogin: boolean;
  isLoadingOTP: boolean;
  initialComplete: boolean;
  phoneNumber: string;
  authToken: string | undefined;
  cachedPhoneNumber: string | undefined;
  setPhoneNumber: React.Dispatch<React.SetStateAction<string>>;
  setIsEnabledEjectBtn: React.Dispatch<React.SetStateAction<boolean>>;
  resendOTPDelay: number;
  paymentMethod: PAYMENT_METHOD;
  onChangePaymentMethod: (method: PAYMENT_METHOD) => () => void;
  isEnabledEjectBtn: boolean;
  promoteCode: string;
  clientSecret: string;
  isLoadingClientStripeSecret: boolean;
  customerId: string | null;
  ejectBattery: (token?: string) => Promise<void>;
  handleEditPaymentMethod: () => void;
  paymentInfo: TPaymentInfo | null;
  onChangePromoteCode: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isRedeemRewardLoading: boolean;
  rewardRedeemError: string | null;
  rewardRedeemSuccessMessage: string | null;
  rentalStartedAt?: string;
  redeemReward: () => Promise<void>;
  chargingBoxPricing: TChargingBoxPricing | null;
  agreePolicy: boolean;
  onAgreePolicy: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const MainContext = createContext<MainContextType>({
  rentalStartedAt: '',
  chargingBoxPricing: null,
  isLoadingClientStripeSecret: false,
  step: null,
  changeStep: () => null,
  setIsEnabledEjectBtn: () => null,
  ejectBattery: () => Promise.resolve(),
  getOTP: () => Promise.resolve(),
  login: () => Promise.resolve(),
  phoneNumber: `+${DEFAULT_COUNTRY_DATA.dialCode}`,
  cachedPhoneNumber: undefined,
  handleEditPaymentMethod: () => null,
  setPhoneNumber: () => null,
  resendOTPDelay: 0,
  isLoadingOTP: false,
  isLoadingLogin: false,
  initialComplete: false,
  paymentMethod: PAYMENT_METHOD.CREDIT_CARD,
  onChangePaymentMethod: () => () => null,
  isEnabledEjectBtn: false,
  clientSecret: '',
  promoteCode: '',
  customerId: null,
  authToken: undefined,
  paymentInfo: null,
  rewardRedeemError: null,
  rewardRedeemSuccessMessage: null,
  isRedeemRewardLoading: false,
  onChangePromoteCode: () => null,
  redeemReward: () => Promise.resolve(),
  agreePolicy: false,
  onAgreePolicy: () => null,
});

type Props = {
  children: React.ReactNode;
};

export const MainProvider: React.FC<Props> = ({ children }) => {
  const [step, setStep] = React.useState<STEP | null>(null);
  const [initialComplete, setInitialComplete] = React.useState(false);
  const [checkStation, setCheckStation] = useState(false);
  const [isLoadingOTP, setIsLoadingOTP] = useState(false);
  const [isLoadingLogin, setIsLoadingLogin] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(
    `+${DEFAULT_COUNTRY_DATA.dialCode}`,
  );
  const [resendOTPDelay, setResendOTPDelay] = useState(0);
  const [authToken, setAuthToken, remove] = useLocalStorage<string>(
    'cf-rental-auth-token',
  );
  const [cachedPhoneNumber, setCachedPhoneNumber, removeCachedPhoneNumber] =
    useLocalStorage<string>('cf-rental-phone-number');

  const [clientSecret, setClientSecret] = useState('');
  const [customerId, setCustomerId] = useState<string | null>(null);
  const [isLoadingClientStripeSecret, setIsLoadingClientStripeSecret] =
    useState(false);
  const [isNavigateToWelcomeBackPage, setIsNavigateToWelcomeBackPage] =
    useState(true);

  const [searchParams] = useSearchParams();

  const [paymentMethod, setPaymentMethod] = useState<PAYMENT_METHOD>(
    PAYMENT_METHOD.CREDIT_CARD,
  );

  const [paymentInfo, setPaymentInfo] = useState<TPaymentInfo | null>(null);

  const [isEnabledEjectBtn, setIsEnabledEjectBtn] = useState(false);
  const [promoteCode, setPromoteCode] = useState('');
  const [rewardRedeemSuccessMessage, setRewardRedeemSuccessMessage] = useState<
    string | null
  >(null);
  const [isRedeemRewardLoading, setIsRedeemRewardLoading] = useState(false);
  const [rewardRedeemError, setRewardRedeemError] = useState<string | null>(
    null,
  );
  const [rentalStartedAt, setRentalStartedAt, removeRentalStartedAt] =
    useLocalStorage<string>('cf-rental-started-at');
  const [chargingBoxPricing, setChargingBoxPricing] =
    useState<TChargingBoxPricing | null>(null);
  const [agreePolicy, setAgreePolicy] = useState(false);

  const navigate = useNavigateInApp();
  const location = useLocation();

  const getChargingBoxPricing = useCallback(async (bcode: string | null) => {
    if (bcode) {
      try {
        const resp = await Parse.Cloud.run('priceModel', { bcode });
        setChargingBoxPricing(resp.toJSON());
      } catch (error) {
        toast.warn('Get charging box pricing failed');
      }
    }
  }, []);

  const getClientStripeSecret = async () => {
    try {
      setIsLoadingClientStripeSecret(true);
      const { client_secret, customerId } = await Parse.Cloud.run(
        'setupIntent',
      );
      setClientSecret(client_secret);
      setCustomerId(customerId);

      return true;
    } catch (error) {
      toast.error('Setup payment intent failed');
      return false;
    } finally {
      setIsLoadingClientStripeSecret(false);
    }
  };

  const clearAllCached = useCallback(() => {
    remove();
    removeCachedPhoneNumber();
    removeRentalStartedAt();
  }, [remove, removeCachedPhoneNumber, removeRentalStartedAt]);

  const getPaymentInfo = async () => {
    try {
      const resp = await Parse.Cloud.run('fetchPaymentInfo');
      setPaymentInfo(resp.toJSON());
    } catch (error) {
      throw error;
    }
  };

  const postPageLoadedProcess = useCallback(async () => {
    // check if user is logged in
    if (authToken) {
      try {
        await Parse.User.become(authToken);
      } catch (error) {
        try {
          await Parse.User.logOut();
        } catch (error) {
          // do nothing
        } finally {
          // clear local storage and logout, then redirect to input phone page
          clearAllCached();
          setInitialComplete(true);
          setStep(STEP.INPUT_PHONE_NUMBER);
        }

        return;
      }

      if (await getClientStripeSecret()) {
        // user logged in, check if payment info is available
        try {
          await getPaymentInfo();
          // handle edit payment in welcome back page
          if (!isNavigateToWelcomeBackPage) return;
          // do not redirect to welcome back page if user is in welcome back / success page
          if (
            location.pathname === '/welcome-back' ||
            location.pathname === '/success'
          ) {
            setInitialComplete(true);
            return;
          }
          // payment info available, redirect to welcome back page
          navigate('/welcome-back');
          setInitialComplete(true);
        } catch (error) {
          if (location.pathname === '/welcome-back') {
            // We are in welcome back page but the payment info doesn't exist.
            // Navigate to input payment info page
            setInitialComplete(true);
            setStep(STEP.INPUT_PAYMENT_INFO);
            navigate('/fuzebox');
            return;
          }
          // payment info not available, redirect to input payment info page
          setInitialComplete(true);
          setStep(STEP.INPUT_PAYMENT_INFO);
        }
      } else {
        setInitialComplete(true);
        setStep(STEP.INPUT_PHONE_NUMBER);
      }
    } else {
      try {
        await Parse.User.logOut();
      } catch (err) {
        // do nothing
      } finally {
        // user not logged in, redirect to input phone page
        setInitialComplete(true);
        clearAllCached();
        setStep(STEP.INPUT_PHONE_NUMBER);
      }
    }
  }, [
    authToken,
    clearAllCached,
    isNavigateToWelcomeBackPage,
    location.pathname,
    navigate,
  ]);
  //check if station is online in new BE
  useEffect(() => {
    if (searchParams.get('box')) {
      checkOnlineStatus(searchParams.get('box') ?? '');
      setCheckStation(true);
    }
  }, [searchParams]);

  useEffect(() => {
    //check if initialCompleted and checked station is online
    checkStation && !initialComplete && postPageLoadedProcess();
  }, [checkStation, initialComplete, postPageLoadedProcess]);

  const changeStep = (step: STEP) => setStep(step);

  const getOTP = async (captchaToken?: string) => {
    if (!isValidPhoneNumber(phoneNumber)) return;
    setCachedPhoneNumber(phoneNumber);

    try {
      setIsLoadingOTP(true);
      await Parse.Cloud.run(
        'sendCode',
        captchaToken ? { phoneNumber, c_tk: captchaToken } : { phoneNumber },
      );
      setStep(STEP.INPUT_OTP);
    } catch (error) {
      toast.error('Get OTP code failed');
    } finally {
      setResendOTPDelay(30);
      setIsLoadingOTP(false);
    }
  };

  const login = async (code: string) => {
    try {
      setIsLoadingLogin(true);
      const authToken = await Parse.Cloud.run('login', { phoneNumber, code });
      setAuthToken(authToken);
      await Parse.User.become(authToken);
      await getChargingBoxPricing(searchParams.get('box'));
      if (await getClientStripeSecret()) {
        try {
          await getPaymentInfo();
          navigate('/welcome-back');
        } catch (error) {
          setStep(STEP.INPUT_PAYMENT_INFO);
        }
      } else {
        setStep(STEP.INPUT_PHONE_NUMBER);
      }
    } catch (error) {
      toast.error('Login failed');
    } finally {
      setIsLoadingLogin(false);
    }
  };

  const ejectBattery = async (token?: string) => {
    try {
      if (token) {
        await Parse.Cloud.run('requestBattery', {
          bcode: searchParams.get('box'),
          token: token,
          channel: 'webapp',
        });
      } else {
        await Parse.Cloud.run('requestBattery', {
          bcode: searchParams.get('box'),
          channel: 'webapp',
        });
      }

      setRentalStartedAt(new Date().toISOString());
      navigate('/success', { replace: true });
    } catch (error) {
      throw new Error(
        (error as Error).message ??
          'An unknown error occurred when eject battery!',
      );
    }
  };

  const onChangePaymentMethod = (paymentMethod: PAYMENT_METHOD) => () => {
    setPaymentMethod(paymentMethod);
  };

  const handleEditPaymentMethod = () => {
    setStep(STEP.INPUT_PAYMENT_INFO);
    setRewardRedeemError(null);
    setRewardRedeemSuccessMessage(null);
    setIsNavigateToWelcomeBackPage(false);
    navigate('/fuzebox');
  };

  const onChangePromoteCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPromoteCode(e.target.value);
    if (rewardRedeemError) setRewardRedeemError(null);
    if (rewardRedeemSuccessMessage) setRewardRedeemSuccessMessage(null);
  };

  const redeemReward = async () => {
    if (!promoteCode) return;

    try {
      setIsRedeemRewardLoading(true);
      setRewardRedeemError(null);
      setRewardRedeemSuccessMessage(null);
      const resp = await Parse.Cloud.run('redeemReward', { code: promoteCode });
      setRewardRedeemSuccessMessage(resp);
    } catch (error) {
      const unknownMessage =
        'An unknown error occurred when redeeming the reward.';
      if (error instanceof Parse.Error) {
        // We can't show this kind of error message to user
        if (error.code === Parse.Error.INVALID_JSON) {
          setRewardRedeemError(unknownMessage);
          return;
        }

        setRewardRedeemError(error.message ?? unknownMessage);

        return;
      }
      // We should console.log the error in this case.
      // eslint-disable-next-line no-console
      console.error(error);
      setRewardRedeemError(unknownMessage);
    } finally {
      setIsRedeemRewardLoading(false);
    }
  };
  const onAgreePolicy = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAgreePolicy(event.target.checked);
  };
  return (
    <MainContext.Provider
      value={{
        rentalStartedAt,
        rewardRedeemSuccessMessage,
        redeemReward,
        rewardRedeemError,
        promoteCode,
        onChangePromoteCode,
        isRedeemRewardLoading,
        ejectBattery,
        paymentInfo,
        cachedPhoneNumber,
        authToken,
        handleEditPaymentMethod,
        customerId,
        clientSecret,
        isLoadingClientStripeSecret,
        isEnabledEjectBtn,
        setIsEnabledEjectBtn,
        paymentMethod,
        initialComplete,
        isLoadingLogin,
        isLoadingOTP,
        step,
        changeStep,
        getOTP,
        phoneNumber,
        setPhoneNumber,
        resendOTPDelay,
        login,
        onChangePaymentMethod,
        chargingBoxPricing,
        agreePolicy,
        onAgreePolicy,
      }}
    >
      {children}
    </MainContext.Provider>
  );
};

export const useMainContext = () => useContext(MainContext);
