import React from 'react';
import { Stack, Typography } from '@mui/material';
import { format } from 'date-fns';

import { MainButton } from '~components/atoms';
import { useMainContext } from '~providers';
import { handleOpenApp } from '~utils/index';

export const EjectSuccess = () => {
  const { rentalStartedAt } = useMainContext();

  return (
    <Stack display="grid" sx={{ gridRowGap: 32 }}>
      <Stack
        p={4}
        bgcolor="primary.main"
        color="white"
        justifyContent="center"
        display="grid"
        textAlign="center"
        sx={{
          gridRowGap: 24,
          borderBottomRightRadius: 25,
          borderBottomLeftRadius: 25,
        }}
      >
        <Stack direction="row" justifyContent={'center'} alignItems="center">
          <img src="/images/check.svg" />
          <Typography
            variant="h5"
            fontSize={'1.75rem'}
            fontWeight={600}
            marginLeft={2}
          >
            Charger ejected!
          </Typography>
        </Stack>
        <Typography variant="body1" fontWeight={600}>
          Rental starts:{' '}
          {rentalStartedAt
            ? format(new Date(rentalStartedAt), 'MMM d, yyyy h:mm a')
            : ''}
        </Typography>
        <Typography variant="body2" fontWeight={500}>
          Remember to take your ejected charger and kindly return to any station
          when you’re done.
        </Typography>
      </Stack>
      <Stack p={3}>
        <Stack
          sx={{ border: '1px solid #99DBFF', borderRadius: 2 }}
          bgcolor={'#F5FBFE'}
        >
          <Stack
            borderBottom={'1px solid #99DBFF'}
            bgcolor={'white'}
            sx={{
              borderTopLeftRadius: 16,
              borderTopRightRadius: 16,
              gridRowGap: 8,
            }}
            p={3}
            display="grid"
          >
            <Stack direction="row">
              <Stack>
                <img src="/images/gift-1.svg" alt="gift" width={50} />
              </Stack>
              <Stack display="grid" sx={{ gridRowGap: 8 }} marginLeft={3}>
                <Typography
                  variant="body1"
                  fontWeight={600}
                  color="primary.main"
                >
                  You’ve unlocked $5 off your next rental
                </Typography>
                <Stack>
                  <Typography variant="body2" fontWeight={400} color="#6A737D">
                    Get the chargeFUZE app today on the app store to claim this
                    reward.
                  </Typography>
                  <Typography variant="body2" fontWeight={400} color="#6A737D">
                    Use code:{' '}
                    <span
                      style={{
                        color: '#009EFA',
                        fontWeight: 600,
                        fontSize: '0.875rem',
                      }}
                    >
                      FUZER
                    </span>
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            <Typography
              variant="body2"
              fontWeight={400}
              fontSize={'12px'}
              color="#6A737D"
              fontStyle={'italic'}
            >
              *This promo code is exclusively valid for first-time users.
              Restrictions apply.*
            </Typography>
          </Stack>

          <Stack p={3} display="grid" sx={{ gridRowGap: 24 }}>
            <Stack direction="row">
              <Stack>
                <img src="/images/phone.png" alt="gift" width={80} />
              </Stack>
              <Stack display="grid" sx={{ gridRowGap: 8 }} marginLeft={2}>
                <Typography variant="body1" fontWeight={600} color="#0081CC">
                  Easier, faster rentals
                </Typography>
                <Typography variant="body2" fontWeight={400} color="#0081CC">
                  Manage rentals, access receipts, enter promo codes, and locate
                  charging stations near you.
                </Typography>
              </Stack>
            </Stack>
            <MainButton variant="contained" onClick={handleOpenApp}>
              <Typography variant="body1" fontWeight={500}>
                Install chargeFUZE app now
              </Typography>
            </MainButton>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
