import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useMount } from 'react-use';
import { Add } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import { MainButton } from '~components/atoms';
import { useNavigateInApp } from '~hooks/index';
import { useMainContext } from '~providers';
import { formatNumber, handleOpenApp } from '~utils/index';

export const WelcomeBack = () => {
  const {
    handleEditPaymentMethod,
    cachedPhoneNumber,
    authToken,
    paymentInfo,
    ejectBattery,
    onChangePromoteCode,
    redeemReward,
    isRedeemRewardLoading,
    promoteCode,
    rewardRedeemError,
    rewardRedeemSuccessMessage,
    initialComplete,
    chargingBoxPricing,
  } = useMainContext();
  const [processing, setProcessing] = useState(false);

  const navigate = useNavigateInApp();

  useMount(() => {
    if (!authToken) {
      navigate('/fuzebox');
    }
  });

  const handleEjectBattery = async (
    event: React.SyntheticEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
    setProcessing(true);

    try {
      // Submit the payment method to your server
      await ejectBattery();
    } catch (err) {
      toast.error((err as Error).message);
    } finally {
      setProcessing(false);
    }
  };

  const onClickViewTerms = () => {
    window?.open(
      process.env.REACT_APP_VIEW_TERMS_URL ??
        'https://www.chargefuze.com/terms-of-service/',
      '_blank',
    );
  };

  if (!initialComplete) {
    return (
      <Stack
        flex={1}
        justifyContent={'center'}
        alignItems="center"
        height={'calc(100vh - 170px)'}
      >
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <Stack display="grid" sx={{ gridRowGap: 32 }} gap={4} p={3}>
      <Stack display="grid" sx={{ gridRowGap: 16 }}>
        <Typography variant="h4" fontWeight={600}>
          Welcome back!
        </Typography>
        <Stack direction={'row'}>
          <Typography variant="h6" fontWeight={600} fontSize={'1.25rem'}>
            You’re ready to
          </Typography>
          <Typography
            sx={{ marginLeft: '0.5rem' }}
            variant="h6"
            fontWeight={600}
            fontSize={'1.25rem'}
            color="primary.main"
          >
            rent a charger.
          </Typography>
        </Stack>
        <Stack display="grid" sx={{ gridRowGap: 4 }}>
          <Typography variant="body1" fontWeight={400} color="#6A737D">
            Confirm or edit your details below.
          </Typography>
          <Stack direction="row" alignItems="center">
            <Typography variant="body1" fontWeight={400} color="#6A737D">
              {`Your card will be pre-authorized for $${
                chargingBoxPricing?.preAuthAmount ?? 10
              }.`}
            </Typography>
            <IconButton sx={{ paddingY: 0 }} onClick={onClickViewTerms}>
              <img src="/images/help.svg" alt="help" />
            </IconButton>
          </Stack>
        </Stack>
      </Stack>
      <Stack
        bgcolor={'#F5FBFE'}
        padding={3}
        color="black"
        borderRadius={2}
        border={'1px solid #99DBFF'}
        alignItems="center"
        display="grid"
        sx={{ gridRowGap: 16 }}
      >
        <Stack direction="row" alignItems="center">
          <Stack width={'25px'} marginRight={2}>
            <img src="/images/phone.svg" alt="phone" width="15px" />
          </Stack>
          <Typography
            variant="body1"
            fontWeight={400}
            color="#586069"
            marginRight="8px"
          >
            Phone number
          </Typography>
          <Typography variant="body1" fontWeight={500} color="primary.main">
            {formatNumber(cachedPhoneNumber?.replace(/\D/g, ''))}
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center">
          <Stack width={'25px'} marginRight={2}>
            <img src="/images/credit_score.svg" alt="phone" width={'19px'} />
          </Stack>
          <Typography
            variant="body1"
            fontWeight={400}
            color="#586069"
            marginRight="8px"
          >
            Card ending in
          </Typography>
          <Typography variant="body1" fontWeight={500} color="primary.main">
            {paymentInfo?.card_last4}
          </Typography>
          <Button
            sx={{ p: 0 }}
            variant="text"
            onClick={handleEditPaymentMethod}
          >
            <Typography variant="body2" sx={{ textDecoration: 'underline' }}>
              Edit
            </Typography>
          </Button>
        </Stack>
      </Stack>
      <Stack>
        <TextField
          error={!!rewardRedeemError}
          variant="outlined"
          placeholder="Have a promo code?"
          onChange={onChangePromoteCode}
          sx={{
            '& .MuiInputBase-root': {
              paddingRight: 'unset',
            },
            '& input::placeholder': {
              color: '#8D8D8D',
              fontWeight: 400,
              fontSize: '1rem',
            },
            '& .MuiInputAdornment-root': {
              height: '100%',
              maxHeight: 'unset',
            },
          }}
          InputProps={{
            sx: {
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#DBDBDB',
              },
              '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                borderColor: 'error.main',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderWidth: '1px !important',
                boxShadow: 'unset !important',
                borderColor: '#DBDBDB',
              },
            },
            endAdornment: (
              <InputAdornment position="end">
                <MainButton
                  sx={{
                    border: 'unset',
                    height: '100%',
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    borderTopRightRadius: '8px',
                    borderBottomRightRadius: '8px',
                  }}
                  variant="contained"
                  onClick={redeemReward}
                  disabled={isRedeemRewardLoading || !promoteCode}
                >
                  {isRedeemRewardLoading ? (
                    <CircularProgress size="16px" color="inherit" />
                  ) : (
                    'Apply'
                  )}
                </MainButton>
              </InputAdornment>
            ),
          }}
        />
        {rewardRedeemError && (
          <Stack direction="row" sx={{ marginTop: 1 }} alignItems="center">
            <img src="/images/error.svg" alt="error" width={13} />
            <Typography
              variant="body2"
              fontWeight={500}
              color="error.main"
              marginLeft={1}
            >
              {rewardRedeemError}
            </Typography>
          </Stack>
        )}
        {rewardRedeemSuccessMessage && (
          <Stack direction="row" sx={{ marginTop: 1 }} alignItems="center">
            <img src="/images/check_circle.svg" alt="success" width={13} />
            <Typography
              variant="body2"
              fontWeight={500}
              color="success.main"
              marginLeft={1}
            >
              {rewardRedeemSuccessMessage}
            </Typography>
          </Stack>
        )}
      </Stack>

      <Stack sx={{ display: 'grid', gridRowGap: 16 }}>
        <MainButton
          variant="contained"
          disabled={processing}
          onClick={handleEjectBattery}
          isLoading={processing}
        >
          <Typography variant="body1" fontWeight={500}>
            Eject battery
          </Typography>
        </MainButton>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent={'space-between'}
        >
          <Box width={'40%'} height={'1px'} bgcolor="#E1E4E8"></Box>
          <Typography variant="body2" fontWeight={400} color="#6A737D">
            OR
          </Typography>
          <Box width={'40%'} height={'1px'} bgcolor="#E1E4E8"></Box>
        </Stack>
        <MainButton
          variant="outlined"
          startIcon={<Add />}
          onClick={handleOpenApp}
        >
          <Typography variant="body1" fontWeight={500}>
            Download chargeFUZE app
          </Typography>
        </MainButton>
      </Stack>
    </Stack>
  );
};
