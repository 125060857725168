import axios, { AxiosRequestConfig, RawAxiosRequestHeaders } from 'axios';
import applyCaseMiddleware from 'axios-case-converter';

const inMemoryJWTService = {
  getToken: () => null,
};

export const axiosClient = applyCaseMiddleware(
  axios.create({ baseURL: process.env.REACT_APP_API_ENDPOINT }),
  {
    ignoreHeaders: true,
  },
);

type GetParams = {
  path: string;
  config?: AxiosRequestConfig;
  customHeader?: RawAxiosRequestHeaders;
  applyMiddleWare?: boolean;
};

type PostParams = GetParams & {
  data?: Record<string, unknown>;
};

export const getRequest = async ({ path, config, customHeader }: GetParams) => {
  const token = inMemoryJWTService.getToken();
  const response = await axiosClient.get(`/${path}`, {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
      ...customHeader,
    },
    ...config,
  });
  return response.data;
};

export const postRequest = ({
  path,
  config,
  customHeader,
  data = {},
}: PostParams) => {
  const token = inMemoryJWTService.getToken();
  return axiosClient.post(`/${path}`, data, {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
      ...customHeader,
    },
    ...config,
  });
};

export const putRequest = ({
  path,
  config,
  customHeader,
  data,
}: PostParams) => {
  const token = inMemoryJWTService.getToken();
  return axiosClient.put(`/${path}`, data, {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
      ...customHeader,
    },
    ...config,
  });
};

export const patchRequest = ({
  path,
  config,
  customHeader,
  data,
}: PostParams) => {
  const token = inMemoryJWTService.getToken();
  return axiosClient.patch(`/${path}`, data, {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
      ...customHeader,
    },
    ...config,
  });
};

export const delRequest = ({ path, config, customHeader }: GetParams) => {
  const token = inMemoryJWTService.getToken();
  return axiosClient.delete(`/${path}`, {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
      ...customHeader,
    },
    ...config,
  });
};
