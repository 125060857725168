import { getRequest } from './client.service';

export const checkOnlineStatus = async (serialNumber: string) => {
  try {
    const status = await getRequest({
      path: `api/station/online-status`,
      config: {
        params: {
          serialNumber: serialNumber,
        },
      },
    });
    if (status.online) {
      const url = `${process.env.REACT_APP_NEW_WEBAPP_URL}fuzebox?box=${serialNumber}`;
      window.location.replace(url);
    }
    return true;
  } catch (error) {
    return false;
  }
};
