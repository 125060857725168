import React, { useEffect, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { MuiOtpInput } from 'mui-one-time-password-input';

import { STEP } from '~common';
import { MainButton } from '~components/atoms';
import { useMainContext } from '~providers';
import { formatNumber } from '~utils/phone.util';

export const StepTwo = () => {
  const {
    phoneNumber,
    getOTP,
    login,
    isLoadingLogin,
    isLoadingOTP,
    changeStep,
  } = useMainContext();
  const [countdownTime, setCountdownTime] = useState(0);
  const [intervalId, setIntervalId] = useState<NodeJS.Timer>();
  const [otpCode, setOTPCode] = useState<string>('');

  const onChangeOTPCode = (value: string) => {
    setOTPCode(value);
  };

  const onLoginClick = () => {
    login(otpCode);
  };

  function handleResendOTPClick() {
    getOTP();
    setCountdownTime(30);
    // Disable the resend OTP button
    setIntervalId(
      setInterval(function () {
        setCountdownTime((prevCountdownTime) => prevCountdownTime - 1);
      }, 1000),
    );
  }

  useEffect(() => {
    if (countdownTime === 0 && intervalId) {
      // Enable the resend OTP button
      clearInterval(intervalId);
    }
  }, [countdownTime, intervalId]);

  return (
    <>
      <Stack display="grid" sx={{ gridRowGap: 16 }}>
        <Typography variant="caption" fontWeight={500} color={'#6A737D'}>
          STEP 2 OF 3
        </Typography>
        <Typography variant="h5" fontWeight={600}>
          Enter verification code
        </Typography>
        <Stack direction="row" gap={0.4} flexWrap={'wrap'}>
          <Typography variant="body1" fontWeight={400} color="#6A737D">
            We sent a verification code to
          </Typography>
          <Typography
            variant="body1"
            fontWeight={400}
            color="primary.main"
            onClick={() => changeStep(STEP.INPUT_PHONE_NUMBER)}
          >
            {formatNumber(phoneNumber?.replace(/\D/g, ''))}
          </Typography>
        </Stack>
      </Stack>
      <MuiOtpInput
        sx={{
          gap: 0,
          '& > div:not(:last-of-type)': {
            marginRight: 2,
          },
          '& .MuiInputBase-input': {
            paddingX: 2,
            paddingY: 3,
            fontSize: '1.5rem',
          },
        }}
        TextFieldsProps={{
          type: 'number',
          inputProps: {
            inputMode: 'numeric',
            pattern: '[0-9]*',
          },
        }}
        inputMode="numeric"
        autoFocus
        value={otpCode}
        onChange={onChangeOTPCode}
      />
      <Stack display="grid" sx={{ gridRowGap: 24 }}>
        <MainButton
          disabled={!otpCode || otpCode?.length !== 4 || isLoadingLogin}
          variant="contained"
          onClick={onLoginClick}
          isLoading={isLoadingLogin}
        >
          <Typography variant="body1" fontWeight={500}>
            Continue
          </Typography>
        </MainButton>
        <MainButton
          isLoading={isLoadingOTP}
          disabled={countdownTime > 0 || isLoadingOTP}
          variant="text"
          onClick={handleResendOTPClick}
        >
          <Typography variant="body1" fontWeight={500}>
            {countdownTime > 0
              ? `Resend code in ${countdownTime}s`
              : 'Resend code'}
          </Typography>
        </MainButton>
      </Stack>
    </>
  );
};
