import React, { useState } from 'react';
import { useMount } from 'react-use';
import { Box, Checkbox, Stack, Typography } from '@mui/material';

import { Captcha, MainButton } from '~components/atoms';
import { PhoneInput } from '~components/atoms';
import { useCaptcha } from '~hooks/index';
import { useMainContext } from '~providers';
import { isDevEnv } from '~utils/index';

export const StepOne = () => {
  const {
    phoneNumber,
    setPhoneNumber,
    getOTP,
    isLoadingOTP,
    agreePolicy,
    onAgreePolicy,
  } = useMainContext();
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState<boolean | null>(
    null,
  );

  const {
    captchaVerified,
    onCaptchaVerified,
    onCaptchaExpired,
    captchaRef,
    captchaToken,
  } = useCaptcha();

  const submit = () => {
    if (!isValidPhoneNumber || (!isDevEnv() && !captchaVerified)) return;

    getOTP(captchaToken);
  };

  useMount(() => {
    setPhoneNumber('+1');
  });

  return (
    <>
      <Stack display="grid" sx={{ gridGap: 16, gridRowGap: 16 }}>
        <Typography variant="caption" fontWeight={500} color={'#6A737D'}>
          STEP 1 OF 3
        </Typography>
        <Typography variant="h5" fontWeight={600}>
          Enter phone number
        </Typography>
        <Typography variant="body1" fontWeight={400} color="#6A737D">
          We’ll text you a code to verify your number.
        </Typography>
      </Stack>
      <Stack display="grid" sx={{ gridGap: 16, gridRowGap: 16 }}>
        <PhoneInput
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          isValid={isValidPhoneNumber}
          setIsValid={setIsValidPhoneNumber}
        />
        {!isDevEnv() && (
          <Stack alignItems={'center'}>
            <Captcha
              forwardRef={captchaRef}
              onVerify={onCaptchaVerified}
              onExpire={onCaptchaExpired}
            />
          </Stack>
        )}
        <Stack direction={'row'} gap={1} alignItems={'flex-start'}>
          <Checkbox
            checked={agreePolicy}
            onChange={onAgreePolicy}
            size="small"
          />
          <Typography
            variant="caption"
            fontWeight={400}
            color="#6A737D"
            lineHeight={'1rem'}
          >
            By submitting your phone number, you confirm that you have read and
            agreed to chargeFUZE's{' '}
            <Box
              component={'a'}
              href="https://www.chargefuze.com/terms-of-service"
              sx={{
                textDecoration: 'unset',
                color: '#009EFA',
                fontWeight: '450',
              }}
              target="_blank"
            >
              Terms of Service
            </Box>{' '}
            and{' '}
            <Box
              component={'a'}
              href="https://www.chargefuze.com/privacy-policy"
              sx={{
                textDecoration: 'unset',
                color: '#009EFA',
                fontWeight: '450',
              }}
              target="_blank"
            >
              Privacy Policy
            </Box>
            , and you consent to receive text messages from chargeFUZE for
            direct marketing purposes. Read our Privacy Policy to learn more
            about our data practices and opting-out of text communications. Msg
            & data rates may apply.{' '}
          </Typography>
        </Stack>
      </Stack>

      <Stack>
        <MainButton
          disabled={
            !isValidPhoneNumber ||
            (!isDevEnv() && !captchaVerified) ||
            isLoadingOTP ||
            !agreePolicy
          }
          variant="contained"
          onClick={submit}
          isLoading={isLoadingOTP}
        >
          <Typography variant="body1" fontWeight={500}>
            Send code
          </Typography>
        </MainButton>
      </Stack>
    </>
  );
};
